<template>
  <div class="container">
    <div class="box" style="margin-right: 10px; margin-left: -50px; ">
      <h3>Dashboard</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardTitle',
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  alignt-content: stretch;
}

.box {
  height: auto;
  width: auto;
  padding: 5%;
}

.container1 {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  alignt-content: stretch;
}
</style>
