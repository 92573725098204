var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"container",staticStyle:{"height":"60px","width":"1100px"}},[_c('dashboard-title',{staticClass:"font  font-md text-uppercase"})],1),_c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{staticClass:"d-flex flex-wrap justify-space-around mb-6",attrs:{"color":"transparent","flat":""}},[_c('information-card',{attrs:{"description":"All Staffs","total":_vm.totalCount.staffCount}}),_c('information-card',{attrs:{"description":"All Transactions (Success/Failed)","total":_vm.totalCount.totalTransactions}}),_c('information-card',{attrs:{"description":"Success Tnx. Count","total":_vm.totalCount.totalSuccessfulTransactionCount}}),_c('information-card',{attrs:{"description":"Total Tnx. Amount","total":_vm._f("currencyFormat")(_vm.totalCount.totalAmount,'GHS')}})],1)],1),_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('h4',{staticClass:" font font-md text-uppercase mx-6 pb-1"},[_vm._v(" Incoming Donations ")]),_c('v-data-table',{staticClass:"font box-shadow-light font-weight-medium text-capitalize font font-md mx-6",attrs:{"items":_vm.members,"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.paginate.total,"loading-text":"Loading... Please wait","footer-props":{
					itemsPerPageOptions: [20, 30, 40],
				}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.createdAt))+" ")]}},{key:"item.amount",fn:function(ref){
				var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.amount))+" ")])]}},{key:"item.paymentStatus",fn:function(ref){
				var item = ref.item;
return [(
							item.paymentStatus &&
								item.paymentStatus.toLowerCase() === 'success'
						)?_c('v-chip',{attrs:{"label":"","small":"","color":'#06c90e'}},[_c('span',{staticClass:" font font-weight-medium font-sm text-uppercase white--text"},[_vm._v(_vm._s('Successful'))])]):(
							item.paymentStatus &&
								item.paymentStatus.toLowerCase() === 'failed'
						)?_c('v-chip',{attrs:{"label":"","small":"","color":'error'}},[_c('span',{staticClass:" font font-weight-medium font-sm text-uppercase"},[_vm._v(_vm._s('Failed'))])]):_c('v-chip',{attrs:{"color":"primary","label":"","small":""}},[_c('span',{staticClass:"font font-weight-medium font-sm text-uppercase"},[_vm._v(" N/A ")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }