<template>
	<v-container>
		<div style="height:60px; width: 1100px;" class="container">
			<dashboard-title class="font  font-md text-uppercase"></dashboard-title>
		</div>
		<!--    border: 2px solid-->
		<div style="width: 100%">
			<v-card
				class="d-flex flex-wrap justify-space-around mb-6"
				color="transparent"
				flat
				><information-card
					description="All Staffs"
					:total="totalCount.staffCount"
				/>
				<information-card
					description="All Transactions (Success/Failed)"
					:total="totalCount.totalTransactions"
				/>
				<information-card
					description="Success Tnx. Count"
					:total="totalCount.totalSuccessfulTransactionCount"
				/>
				<information-card
					description="Total Tnx. Amount"
					:total="totalCount.totalAmount | currencyFormat('GHS')"
				/>
			</v-card>
		</div>
		<v-row style="width: 100%">
			<v-col cols="12" md="12" lg="12">
				<h4 class=" font font-md text-uppercase mx-6 pb-1">
					Incoming Donations
				</h4>

				<v-data-table
					:items="members"
					:headers="headers"
					:options.sync="options"
					:server-items-length="paginate.total"
					class="font box-shadow-light font-weight-medium text-capitalize font font-md mx-6"
					loading-text="Loading... Please wait"
					:footer-props="{
						itemsPerPageOptions: [20, 30, 40],
					}"
				>
					<template #item.updatedAt="{item}">
						{{ item.createdAt | dateFormat }}
					</template>
					<template #item.amount="{item}">
						<v-chip small label color="primary">
							{{ item.amount | currencyFormat }}
						</v-chip>
					</template>
					<template #item.paymentStatus="{item}">
						<v-chip
							v-if="
								item.paymentStatus &&
									item.paymentStatus.toLowerCase() === 'success'
							"
							label
							small
							:color="'#06c90e'"
						>
							<span
								class=" font font-weight-medium font-sm text-uppercase white--text"
								>{{ 'Successful' }}</span
							>
						</v-chip>
						<v-chip
							v-else-if="
								item.paymentStatus &&
									item.paymentStatus.toLowerCase() === 'failed'
							"
							label
							small
							:color="'error'"
						>
							<span class=" font font-weight-medium font-sm text-uppercase">{{
								'Failed'
							}}</span>
						</v-chip>
						<v-chip v-else color="primary" label small>
							<span class="font font-weight-medium font-sm text-uppercase">
								N/A
							</span>
						</v-chip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import DashboardTitle from '@/components/component/DashboardTitle';
import InformationCard from '@/components/component/InformationCard';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
	components: { InformationCard, DashboardTitle },
	computed: {
		...mapGetters({
			totalCount: 'reports/getTotalCount',
			members: 'reports/getNewMembers',
			paginate: 'reports/getPagination',
		}),
	},
	data() {
		return {
			statuses: {},
			options: {},
			headers: [
				{ text: 'Recorded Date', value: 'updatedAt' },
				{
					text: 'Account Number',
					value: 'contact',
					filterable: true,
					sortable: false,
				},
				{
					text: 'Amount (GHC)',
					value: 'amount',
					filterable: false,
					sortable: false,
				},
				{
					text: 'Payment Status',
					value: 'paymentStatus',
					filterable: false,
					sortable: false,
				},
			],
		};
	},
	watch: {
		options: {
			handler(value) {
				const { page, itemsPerPage } = value;
				this.$store.dispatch('reports/newlyAddedDonations', {
					page,
					size: itemsPerPage,
				});
			},
			deep: true,
		},
	},
	filters: {
		dateFormat(value) {
			return moment(value).format('YYYY-MM-DD HH:MM:ss A');
		},
		currencyFormat(value) {
			return new Intl.NumberFormat('en-GB', {
				currency: 'GHS',
				style: 'currency',
				minimumFractionDigits: 2,
			}).format(value);
		},
	},
	created() {
		let payload = {
			page: 1,
			size: 20,
		};
		this.$store.dispatch('reports/newlyAddedDonations', payload);
		this.$store.dispatch('reports/totalCount');
	},
};
</script>
<style>
.container {
	display: flex;
	flex-direction: column;
	wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	alignt-content: space-around;
}

.box {
	height: auto;
	width: auto;
	padding: 3%;
}
</style>
