<template>
  <v-card flat elevation="0" style="border-radius: 0.4rem">
    <div class="container" style=" border-radius: 0.5rem">
      <!--      border: 0.01rem solid #b2bec3;-->
      <div class="container4">
        <v-row class="box4">
          <v-icon color="black" size="30" class="ma-1">{{ icon }}</v-icon>
          <h5 class="ma-2 font font-md text-center" style="color: black">
            {{ description }}
          </h5>
        </v-row>
        <v-row class="box4"
          ><h2 class="font font-md" style="text-align:center">
            {{ total }}
          </h2></v-row
        >
        <v-row class="box4">
          <hr
            style="width:100px; height: 5px; color:#b2bec3; border-radius: 10px; "
          />
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'InformationCard',
  props: ['icon', 'total', 'description'],
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: center;
  align-items: center;
  alignt-content: space-between;
  height: 150px;
  width: 220px;
  margin: 2px;
}

.box {
  height: auto;
  width: auto;
  padding: 5%;
}

.container4 {
  display: flex;
  flex-direction: column;
  wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  alignt-content: space-around;
}

.box4 {
  height: auto;
  width: auto;
  padding: 3%;
}
</style>
